<template>
  <van-popup v-model:show="show" :style="{ padding: '30px' }" round class="pop">
    <van-image width="300" :src="'https://my.tmasbot.com/' + baseInfo.items.imgFocus" />
    <h2>{{ langs.items.congratulations }}</h2>
    <p>{{ langs.items.you_have_a_chance }}</p>
    <p>{{ langs.items.please_click }}</p>
    <van-button type="primary" size="large" color="#000" @click="closepop">{{ langs.items.claim_gift }}</van-button>
  </van-popup>

  <van-popup v-model:show="show2" :style="{ padding: '30px' }" round class="pop">
    <img class="temblor_inf" :src="'https://my.tmasbot.com/' + baseInfo.items.imgFocus" />
    <h2>{{ langs.items.congratulations }}</h2>
    <p> {{ langs.items.your_answer }}</p>
    <p>{{ langs.items.you_have_a_chance }}</p>
    <p> {{ langs.items.you_have_3_attempts }}</p>
    <van-button type="primary" size="large" color="#000" @click="closepop2" :style="{ height: '40px' }">{{
      langs.items.ok
    }}</van-button>
  </van-popup>

  <van-popup v-model:show="show3" :style="{ padding: '30px' }" round class="pop">
    <van-icon name="close" color="#f27474" size="80px" />
    <h2> {{ langs.items.unfortunately }}</h2>
    <p> {{ langs.items.sorry_the_box }}</p>

    <van-button type="primary" size="large" color="#000" @click="closepop3" :style="{ height: '40px' }">{{
      langs.items.ok }}</van-button>
  </van-popup>
  <van-popup v-model:show="show4" :style="{ padding: '30px' }" round class="pop">
    <van-image height="180" :src="'https://my.tmasbot.com/' + baseInfo.items.imgOutBox" />
    <h2>{{ langs.items.congratulations }}</h2>
    <p>{{ langs.items.you_did_it }} </p>
    <p>*** {{ langs.items.the_rules }} ***</p>
    <p> {{ langs.items.you_must_tell }}</p>
    <p>{{ langs.items.enter_your_addres }}</p>
    <p>{{ langs.items.the_gifts_will }}</p>

    <van-button type="primary" size="large" color="#000" @click="closepop4" :style="{ height: '40px' }">{{
      langs.items.ok
    }}</van-button>
  </van-popup>

  <van-popup v-model:show="show5" :style="{ padding: '30px' }" round class="pop">
    <van-icon name="warning-o" color="#f8bb86" size="80px" />
    <h2> {{ langs.items.unfortunately }}</h2>
    <p>{{ langs.items.you_have_to_share }}</p>

    <van-button type="primary" size="large" color="#000" @click="closepop5" :style="{ height: '40px' }">{{
      langs.items.ok
    }}</van-button>
  </van-popup>
  <van-popup v-model:show="show6" :style="{ padding: '30px' }" round class="pop">
    <van-icon name="warning-o" color="#f8bb86" size="80px" />
    <p> {{ langs.items.sharing_failed }}</p>
    <p>{{ langs.items.the_same_group }}</p>

    <van-button type="primary" size="large" color="#000" @click="closepop6" :style="{ height: '40px' }">{{
      langs.items.ok
    }}</van-button>
  </van-popup>
  <div class="top">
    <div class="toprow">
      <van-image style="max-width: 120px;" :src="'https://my.tmasbot.com/' + baseInfo.items.imgLogoL" class="topleft" />
      <van-image height="40px" :src="'https://my.tmasbot.com/' + baseInfo.items.imgLogo" class="toplogo" />
      <van-image style="max-width: 120px;" :src="'https://my.tmasbot.com/' + baseInfo.items.imgLogoR" class="topright" />
    </div>
  </div>
  <div class="content">
    <div class="banner"><van-notice-bar color="#fbbf24" background="#000" scrollable
        :text="'🎉' + langs.items.title + '🎊'" style="width:70%" /><span class="time"> {{ time
        }}</span></div>
    <div class="qa">
      <div class="main-content flag" v-show="qadiv">
        <h1 class="w700">{{ langs.items.congratulations }}</h1>
        <p>{{ langs.items.title }}</p>
        <p>{{ langs.items.through_the }}</p>
        <van-image width="100%" :src="'https://my.tmasbot.com/' + baseInfo.items.imgFocus" />
        <div>
          <div class="main-content flag">
            <div id="q1" v-if="qa === 'q1'">
              <p class="question"><strong>{{ langs.items.question_1 }} :</strong> {{ langs.items.do_you_know
              }}
              </p>
              <div class="survey_button " @click="bq1">{{ langs.items.yes }}</div>
              <div class="survey_button " @click="bq1">{{ langs.items.no }}</div>
            </div>
            <div id="q2" v-else-if="qa === 'q2'">
              <p class="question"><strong>{{ langs.items.question_2 }}:</strong> {{ langs.items.how_old }}</p>
              <div class="survey_button " @click="bq2">18-29</div>
              <div class="survey_button " @click="bq2">30-39</div>
              <div class="survey_button " @click="bq2">40-49</div>
              <div class="survey_button " @click="bq2">50+</div>
            </div>
            <div id="q3" v-else-if="qa === 'q3'">
              <p class="question"><strong>{{ langs.items.question_3 }}:</strong>{{ langs.items.what_do_you }}
              </p>
              <div class="survey_button " @click="bq3">{{ langs.items.very_good }}</div>
              <div class="survey_button " @click="bq3">{{ langs.items.excellent }}</div>
              <div class="survey_button " @click="bq3">{{ langs.items.ok2 }}</div>
              <div class="survey_button " @click="bq3">{{ langs.items.not_too }}</div>
            </div>
            <div id="q4" v-else-if="qa === 'q4'">
              <p class="question"><strong>{{ langs.items.question_4 }} :</strong> {{ langs.items.are_you_male
              }}</p>
              <div class="survey_button " @click="bq4">{{ langs.items.female }}</div>
              <div class="survey_button " @click="bq4">{{ langs.items.male }}</div>
            </div>
            <div v-else></div>
          </div>
        </div>
      </div>
      <div class="center" v-show="Spinning">
        <h1> {{ langs.items.we_are_verifying }}</h1>
        <div class="center1">
          <div class="loader"></div>
        </div>
        <p class="result" v-show="result1">
          {{ langs.items.you_have_answered }}
        </p>
        <p class="result" v-show="result2">
          {{ langs.items.your_ip }}
        </p>
        <p class="result" v-show="result3">
          {{ langs.items.gifts_are }}
        </p>
      </div>


      <div class="main-content " v-show="lottery">

        <div id="boxes" class="boxes">

          <van-grid :column-num="3" :border="false">
            <van-grid-item @click="boxdraw(1)">
              <div class="try" :class="{ 'abierta': t === 1, 'premiazo': tt === 1 }">
                <div class="caja_tapa">
                  <van-image width="100%" :src="box1" />
                </div>
                <div class="caja_trasera">
                  <van-image width="100%" :src="box2" />
                </div>
                <div class="caja_gift">
                  <van-image width="100%" :src="'https://my.tmasbot.com/' + baseInfo.items.imgInBox" />
                </div>
                <div class="caja">
                  <van-image width="100%" :src="box3" />
                </div>
              </div>
            </van-grid-item>
            <van-grid-item @click="boxdraw(2)">
              <div class="try" :class="{ 'abierta': t === 2, 'premiazo': tt === 2 }">
                <div class="caja_tapa">
                  <van-image width="100%" :src="box1" />
                </div>
                <div class="caja_trasera">
                  <van-image width="100%" :src="box2" />
                </div>
                <div class="caja_gift">
                  <van-image width="100%" :src="'https://my.tmasbot.com/' + baseInfo.items.imgInBox" />
                </div>
                <div class="caja">
                  <van-image width="100%" :src="box3" />
                </div>
              </div>
            </van-grid-item>
            <van-grid-item @click="boxdraw(3)">
              <div class="try" :class="{ 'abierta': t === 3, 'premiazo': tt === 3 }">
                <div class="caja_tapa">
                  <van-image width="100%" :src="box1" />
                </div>
                <div class="caja_trasera">
                  <van-image width="100%" :src="box2" />
                </div>
                <div class="caja_gift">
                  <van-image width="100%" :src="'https://my.tmasbot.com/' + baseInfo.items.imgInBox" />
                </div>
                <div class="caja">
                  <van-image width="100%" :src="box3" />
                </div>
              </div>
            </van-grid-item>
            <van-grid-item @click="boxdraw(4)">
              <div class="try" :class="{ 'abierta': t === 4, 'premiazo': tt === 4 }">
                <div class="caja_tapa">
                  <van-image width="100%" :src="box1" />
                </div>
                <div class="caja_trasera">
                  <van-image width="100%" :src="box2" />
                </div>
                <div class="caja_gift">
                  <van-image width="100%" :src="'https://my.tmasbot.com/' + baseInfo.items.imgInBox" />
                </div>
                <div class="caja">
                  <van-image width="100%" :src="box3" />
                </div>
              </div>
            </van-grid-item>
            <van-grid-item @click="boxdraw(5)">
              <div class="try" :class="{ 'abierta': t === 5, 'premiazo': tt === 5 }">
                <div class="caja_tapa">
                  <van-image width="100%" :src="box1" />
                </div>
                <div class="caja_trasera">
                  <van-image width="100%" :src="box2" />
                </div>
                <div class="caja_gift">
                  <van-image width="100%" :src="'https://my.tmasbot.com/' + baseInfo.items.imgInBox" />
                </div>
                <div class="caja">
                  <van-image width="100%" :src="box3" />
                </div>
              </div>
            </van-grid-item>
            <van-grid-item @click="boxdraw(6)">
              <div class="try" :class="{ 'abierta': t === 6, 'premiazo': tt === 6 }">
                <div class="caja_tapa">
                  <van-image width="100%" :src="box1" />
                </div>
                <div class="caja_trasera">
                  <van-image width="100%" :src="box2" />
                </div>
                <div class="caja_gift">
                  <van-image width="100%" :src="'https://my.tmasbot.com/' + baseInfo.items.imgInBox" />
                </div>
                <div class="caja">
                  <van-image width="100%" :src="box3" />
                </div>
              </div>
            </van-grid-item>
            <van-grid-item @click="boxdraw(7)">
              <div class="try" :class="{ 'abierta': t === 7, 'premiazo': tt === 7 }">
                <div class="caja_tapa">
                  <van-image width="100%" :src="box1" />
                </div>
                <div class="caja_trasera">
                  <van-image width="100%" :src="box2" />
                </div>
                <div class="caja_gift">
                  <van-image width="100%" :src="'https://my.tmasbot.com/' + baseInfo.items.imgInBox" />
                </div>
                <div class="caja">
                  <van-image width="100%" :src="box3" />
                </div>
              </div>
            </van-grid-item>
            <van-grid-item @click="boxdraw(8)">
              <div class="try" :class="{ 'abierta': t === 8, 'premiazo': tt === 8 }">
                <div class="caja_tapa">
                  <van-image width="100%" :src="box1" />
                </div>
                <div class="caja_trasera">
                  <van-image width="100%" :src="box2" />
                </div>
                <div class="caja_gift">
                  <van-image width="100%" :src="'https://my.tmasbot.com/' + baseInfo.items.imgInBox" />
                </div>
                <div class="caja">
                  <van-image width="100%" :src="box3" />
                </div>
              </div>
            </van-grid-item>
            <van-grid-item @click="boxdraw(9)">
              <div class="try" :class="{ 'abierta': t === 9, 'premiazo': tt === 9 }">
                <div class="caja_tapa">
                  <van-image width="100%" :src="box1" />
                </div>
                <div class="caja_trasera">
                  <van-image width="100%" :src="box2" />
                </div>
                <div class="caja_gift">
                  <van-image width="100%" :src="'https://my.tmasbot.com/' + baseInfo.items.imgInBox" />
                </div>
                <div class="caja">
                  <van-image width="100%" :src="box3" />
                </div>
              </div>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
      <div class="main-content " v-show="share">
        <div class="sharetit">{{ langs.items.congratulations }}</div>
        <div class="shareimg"><van-image height="170px" :src="'https://my.tmasbot.com/' + baseInfo.items.imgOutBox" />
        </div>
        <div class="sharetext">{{ langs.items.your_prize_is }}
        </div>
        <div class="sharemain">
          <div class="share_ing" v-show="shareing">
            <br>
            <span class="mtop"> {{ langs.items.share_with_5_groups }}</span>
            <br>
            <span class="mtop"> {{ langs.items.click_continue }}</span>

            <div class="buttom"><van-button icon="share-o" type="primary" size="large" color="#179cde" @click="sharete"
                class="custom-button"> Telegram
              </van-button></div>

            <div class="sharetxt">{{ langs.items.share_it_until }}</div>
            <div class="progress">
              <div class="progress-bar progress-bar-success" :style="{ width: progress + '%' }">{{ progress
              }}%
              </div>
            </div>
            <div class="buttom2"><van-button type="primary" size="large" color="#000" @click="conti" class="bu2">
                {{ langs.items.continue }}
              </van-button></div>
          </div>
          <div class="share_success" v-show="success"><br>
            <p class="successp">
              {{ langs.items.the_last_step }}
            </p>

            <p class="successp">
              {{ langs.items.you_must_register }}
            </p>
            <p class="successp">
              {{ langs.items.remember_this_step }}
            </p>
            <p class="successp">
              {{ langs.items.after_completing }}
            </p>
            <br><br><br>

          </div>
        </div>
      </div>
      <div class="main-content " style="margin-top: 10px">
        <div class="comments_face">
          <div class="comments">
            <p class="commentstitle">
              <span style="color:#3b5998">{{ langs.items.comment }}</span>
            </p>
            <p class="comright">
              10 / 183
            </p>
            <div style="clear:both">
            </div>
          </div>


          <div class="detail_block" v-for="(  item, index  ) in   comments.items  ">
            <div class="detail_left"><img :src="'https://my.tmasbot.com/' + item.photo"></div>
            <div class="detail_right">
              <h3>
                <div class="comm_name">{{ item.username }}</div>
              </h3>
              <p>{{ item.text }}</p> <span class="likebar">
                <div class="fbblue smaller" href="javascript:void(0);"><svg viewBox="0 0 24 24"
                    preserveAspectRatio="xMidYMid meet" focusable="false" class="style-scope yt-icon"
                    style="pointer-events: none; display: block; width: 4vw; height: 6vw;">
                    <g class="style-scope yt-icon">
                      <path
                        d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01L23 10z"
                        class="style-scope yt-icon"></path>
                    </g>
                  </svg></div>&nbsp; <div class="fbblue smaller" href="javascript:void(0);"><svg viewBox="0 0 24 24"
                    preserveAspectRatio="xMidYMid meet" focusable="false" class="style-scope yt-icon"
                    style="pointer-events: none; display: block; width: 4vw; height: 6vw;">
                    <g class="style-scope yt-icon">
                      <path
                        d="M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v1.91l.01.01L1 14c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.59-6.59c.36-.36.58-.86.58-1.41V5c0-1.1-.9-2-2-2zm4 0v12h4V3h-4z"
                        class="style-scope yt-icon"></path>
                    </g>
                  </svg></div>&nbsp; <span>{{ randomNumber }} minutes ago</span>
              </span>
            </div>
          </div>






          <p style="font-size:12px;color:#bcbcbc"></p>
        </div>
      </div>



    </div>
  </div>
  <div class="footer">
    <p style="line-height:10px;">
      ©2024 {{ langs.items.brand }} Inc.
    </p>
  </div>
</template>


<script setup>
import { flowerDrops, stopp } from './assets/flowerDrops.js'
import api from "@/api/api.js";
import moment from "moment"
import { ref, reactive, onMounted, onActivated, onDeactivated } from 'vue';

const comments = reactive({
  items: []
})
const langs = reactive({
  items: []
})
const baseInfo = reactive({
  items: []
})
const ym = ref()
api({
  method: "post",
}).then((res) => {
  const a = JSON.stringify(res.data)
  const b = JSON.stringify(res.data.langs)
  const c = JSON.stringify(res.data.comments)
  const d = JSON.stringify(res.data.baseInfo)
  const e = JSON.stringify(res.data.domain)
  const f = JSON.stringify(res.data.randStr)
  const g = 'https://' + JSON.parse(e) + '/' + JSON.parse(f)
  langs.items = JSON.parse(b)
  comments.items = JSON.parse(c)
  baseInfo.items = JSON.parse(d)
  ym.value = g

});
const randomNumber = ref(0)
randomNumber.value = Math.floor(Math.random() * 60);

function vc() {
  console.log(document.visibilityState)
}


onMounted(() => {
  document.addEventListener('visibilitychange', vc)
})



const show = ref(false);

onMounted(() => {
  Telegram.WebApp.expand();
  const a = sessionStorage.getItem("a")
  if (a == 1) {
    share.value = true
    qadiv.value = false
    const sn = sessionStorage.getItem("sharenum")
    if (sn) {
      sharenum.value = sn
      progress.value = pro[sharenum.value]
    }
  } else {
    show.value = true
  }

})

function closepop() {
  show.value = false
}


const time = moment().format('YYYY-MM-DD')

const qa = ref('q1')

function bq1() {
  qa.value = 'q2'
}
function bq2() {
  qa.value = 'q3'
}
function bq3() {
  qa.value = 'q4'
}

const qadiv = ref(true)
const Spinning = ref(false)
const result1 = ref(false)
const result2 = ref(false)
const result3 = ref(false)
function bq4() {
  qadiv.value = false
  Spinning.value = true
  setTimeout(() => {
    result1.value = true
  }, 3000);
  setTimeout(() => {
    result2.value = true
  }, 5000);
  setTimeout(() => {
    result3.value = true
  }, 8000);
  setTimeout(() => {
    tq()
  }, 9000);
}
const show2 = ref(false)
const lottery = ref(false)
function tq() {
  show2.value = true
  lottery.value = true
  Spinning.value = false
}

function closepop2() {
  show2.value = false
}
function closepop3() {
  show3.value = false
}
function closepop5() {
  show5.value = false
}
function closepop6() {
  show6.value = false
}
const share = ref(false)
function closepop4() {
  show4.value = false
  stopp()
  lottery.value = false
  share.value = true
  sessionStorage.setItem('a', '1')
}
const show3 = ref(false)
const show4 = ref(false)
const show5 = ref(false)
const show6 = ref(false)
const boxnum = ref(0)

const t = ref(0)
const tt = ref(0)
function boxdraw(num) {
  if (boxnum.value < 1) {
    t.value = num
    boxnum.value = 1
    setTimeout(() => {
      show3.value = true
    }, 1000);
  } else {

    t.value = num
    tt.value = num
    setTimeout(() => {
      flowerDrops()
    }, 2000);
    setTimeout(() => {
      show4.value = true
    }, 3000);
  }
}
const box1 = require('./assets/box1.png')
const box2 = require('./assets/box2.png')
const box3 = require('./assets/box3.png')

const sharenum = ref(0)
const progress = ref(null)
const shareing = ref(true)
const success = ref(false)
const pro = reactive([50, 50, 70, 70, 80, 85, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 99.1, 99.2, 99.3, 99.4, 99.5, 99.6, 99.7, 99.8, 99.9, 100]);
function sharete() {
  if (sharenum.value < 25) {
    shareBtn()
    setTimeout(() => {
      progress.value = pro[sharenum.value]
      sharenum.value++
    }, 1000);

    sessionStorage.setItem('sharenum', sharenum.value)
    if (sharenum.value == 2 || sharenum.value == 3 || sharenum.value == 4) {

      show6.value = true
    }
  } else {
    shareing.value = false
    success.value = true
  }
}

function conti() {
  if (sharenum.value > 24) {
    shareing.value = false
    success.value = true
  } else {
    show5.value = true
  }
}




function shareBtn() {
  Telegram.WebApp.openTelegramLink('https://t.me/share/url?url=https%3A%2F%2Ft.me%2Fshare68bot%3Fstart%3D305fb34994a5f673&text=Read%20and%20get%20a%20chance%20to%20earn%20%24USD%20and%20%24TON%21%20Get%20Ready%3A%20Read%2C%20Share%2C%20and%20Earn%20with%20ShareNow')
}
</script>
<style scoped>
body {
  font-family: Tahoma, Geneva, sans-serif;

}

.pop {
  text-align: center;
}

.top {
  position: fixed;
  width: 98%;
  height: 40px;
  z-index: 99;
  background: #ffffff;
  opacity: 0.95;
  padding: 9px;
}

.toprow {
  width: 100%;
  display: inline-flex;
  align-items: center;
}

.topleft {
  position: absolute;
}

.toplogo {
  position: relative;
  margin: 0 auto;
  max-width: 200px;
}

.topright {
  position: absolute;
  right: 14px;
}

.banner {
  color: rgb(251, 191, 36);
  background: rgb(0, 0, 0);
  line-height: 300%;
  padding-left: 5px;
  font-size: 14px;
  display: flex;
}

.time {
  float: right;
  padding-right: 5px;
}

.content {
  position: relative;
  top: 58px;
}

.qa {
  padding: 10px;
}

.main-content {
  padding: 10px;
  background-color: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  color: #232f3f;
  background-color: #fff;
}

.flag {
  background: 98% 20px no-repeat #fff;
}

.w700 {
  font-weight: 700;
}

p.question {
  font-size: 15px;
}

.survey_button {
  border: 2px solid #f0f0f0;
  background-color: #000000 !important;
  color: #fbbf24 !important;
  padding: 6px 0;
  margin: 5px 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .74);
  border-radius: 10px;
  text-align: center;
}

.comments_face {
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
}

.comments_face .comments {
  background-color: #eeeff4;
  border-bottom: 2px solid #fff;
  padding: 5px;
}

.commentstitle {
  margin: 0;
  padding: 0;
  float: left;
  display: block;
  width: 50%;

}

.comright {
  margin: 0;
  padding: 0;
  float: right;
  display: block;
  width: 50%;
  color: #a8a7a7;
  text-align: right;
}

.detail_block {
  width: 100%;
  height: auto;
  /* float: left; */
  padding: 0 0 5px;
  margin: 10px 0 0px 0;
  border-bottom: 1px solid #eaeaea;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.main-content p {
  line-height: 18px;
  margin: 10px 0;
  font-size: 14px;
}

.detail_left {
  width: 20%;
  padding: 0;
}

.detail_right {
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  text-align: left;
}

.detail_right h3 {
  width: 100%;
  /* float: left; */
  padding: 0;
  margin: 0 0 5px;
  font-size: 1rem;
  color: #3b5998;
  font-weight: 700;
}

.detail_right h3 .comm_name {
  color: #000000;
}

.detail_right span {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0 0 5px;
  font-size: 13px;
  color: #818181;
}

.likebar {
  float: right;
  color: #7F7F7F;
  display: flex;
  flex-direction: row;
  line-height: 24px;
  align-items: center;
  justify-content: center;
}

.detail_left img {
  width: 15vw;
  max-width: 15vw;
  height: 15vw;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #000000;
  margin-bottom: 0;
  font-size: 12px;
  text-align: center;
  color: #fbbf24;
  padding: 5px;
}

.center {
  text-align: center;
  justify-content: center;
}

.center1 {
  display: flex;
  justify-content: center;
}

.loader {
  border: 4px solid #f3f3f3;
  /* Light grey */
  border-top: 4px solid #3e55a5;
  /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.result {
  font-weight: 700;
  color: #000;
}

.temblor_inf {
  animation: temblor_inf 2s;
  animation-iteration-count: infinite;
}

.temblor_inf {
  animation: temblor_inf 2s;
  animation-iteration-count: infinite;
  margin: 0px;
  height: 140px;
}

@keyframes temblor_inf {
  0% {
    transform: translate(1px, 1px) rotate(0deg)
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg)
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg)
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg)
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg)
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg)
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg)
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg)
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg)
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg)
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg)
  }
}

.sharetit {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #FF0000;
}

.shareimg {
  text-align: center;
  margin: 20px 0;
}

.sharetext {
  margin: 0 5px;
  text-align: center;
  font-size: 14px;
  color: #222222;
}

.sharemain {
  margin: 10px auto;
  width: 100%;
  background: #eeeff4;
  border-radius: 10px;
}

.share_ing {
  margin: 0 15px;
}

.mtop {
  margin-top: 5px;
}

.try {
  position: relative;
  display: inline-block;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  cursor: pointer;
}

.try>div {
  position: absolute;
}

.caja_tapa {
  z-index: 3;
}

.caja_trasera {
  z-index: 1;
}

.caja_gift {
  z-index: 1;
}

.caja_gift {
  width: 98%;
  height: 90%;
}

.caja {
  z-index: 2;
}

.try .caja {
  position: relative;
}

.boxes .try.abierta>.caja_tapa {
  animation: tapa_superior 2s forwards;
}

.boxes .try.premiazo>.caja_gift {
  animation: tapa_superior 4s infinite alternate
}

@keyframes tapa_superior {
  from {
    top: 0
  }

  to {
    top: -40px
  }
}

.buttom {
  color: #fff;
  display: block;
  width: 80%;
  margin: 10px auto;
}

.buttom2 {
  color: #fff;
  display: block;
  width: 80%;
  margin: 3px auto;
  padding-bottom: 10px;
}

.sharetxt {
  text-align: center;
  color: #999999;
  font-size: 14px;
}

.progress {
  margin: 10px 0;
  border-radius: 19px;
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #F5F5F5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  overflow: hidden;
  line-height: 0;
  font-size: .75rem;
}

.progress-bar {
  background-color: #0876b9;
  color: #fff;
}

.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #FFF;
  text-align: center;

  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.progress-bar-success {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;

  transition: width .6s ease;
}

.progress-bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
  background-size: 40px 40px;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

.share_success {
  margin: 0px 15px;
  display: block;
}

.successp {
  padding: 10px 0;
}

.custom-button {
  height: 28px;
  font-size: 14px;
  font-family: "Font Awesome 6 Brands";
}

.bu2 {
  height: 36px;
  font-size: 17px;
  font-weight: 700;
  font-family: "Font Awesome 6 Brands";
}
</style>
