import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'vant/lib/index.css'
import axios from "./api/api.js"
import { Popup, Image as VanImage, Button, NoticeBar, Grid, GridItem, Icon } from 'vant';
const app = createApp(App)
app.use(Popup);
app.use(VanImage);
app.use(Button);
app.use(NoticeBar);
app.use(Grid);
app.use(GridItem);
app.use(Icon);
app.provide("$axios", axios);



app.use(router)
app.mount('#app')
